input {
    transform: scale(1);
}

/*[type=text], select, textarea*/
input[type=email] {
    width: 50%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    margin-left: 50px;
}

textarea {
    width: 50%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    margin-left: 20px;
}

input[type=text] {
    width: 50%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    margin-left: 48px;
}

/*input[type=email], select, textarea {*/
/*    width: 50%;*/
/*    padding: 12px;*/
/*    border: 1px solid #ccc;*/
/*    border-radius: 4px;*/
/*    box-sizing: border-box;*/
/*    margin-top: 6px;*/
/*    margin-bottom: 16px;*/
/*    resize: vertical;*/
/*}*/

input[type=submit] {
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: #45a049;
}

.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
}

@media (max-width: 600px) {
    .contact-form {
        padding: 10px;
        margin: 10px;
        border: black 1px solid;
        width: 90%;
    }
}

@media (min-width: 600px) {
    .contact-form {
        /*width: 50%;*/
        padding: 10px;
        border: black 1px solid;
        margin-right: 10%;
        margin-left: 10%;
    }
}

@media (min-width: 800px) {
    .contact-form {
        /*width: 50%;*/
        padding: 10px;
        border: black 1px solid;
        margin-right: 20%;
        margin-left: 20%;
    }
}

@media (min-width: 1000px) {
    .contact-form {
        /*width: 50%;*/
        padding: 10px;
        border: black 1px solid;
        margin-right: 30%;
        margin-left: 30%;
    }
}
