.card-image {
  max-width: 100%;
  height: auto;
  /*width: 500px;*/
}

.project-image {
  position: relative;
  height: 300px;
  overflow: hidden;
  text-align: center;
}

.card-title {
  color: #000000;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  margin: 15px 0 0 0;
}
.card-subtitle {
  color: #666666;
  font-size: 17px;
  line-height: 1.5rem;
}
.project-card {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  padding: 1.5rem;
  border-radius: 10px;
  min-height: 550px;
  border: 1px solid rgba(211, 211, 211, 0.397);
  justify-content: center;
}
.project-card-light {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.project-card-dark {
  box-shadow: 0px 10px 30px -15px #d9dbdf;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.25s ease;
}

.project-card-footer {
  margin-top: 30px;
}

.project-detail {
  text-align: center;
}
.project-card-light:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}
.project-card-dark:hover {
  box-shadow: #d9dbdf 0px 20px 30px -10px;
}

.projects-container {
  display: grid;
  /*grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));*/
  gap: 1rem 1rem;
}

.project-card-footer span.project-tag {
  background: #55198b;
  color: #f5f2f4;
  vertical-align: middle;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0 0.75em;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.technology {
  display: flex;
  flex-wrap: wrap;
}

.technology span {
  background: #28A745;
  color: white;
  margin-right: 5px;
  margin-top: 10px;
  border-radius: 3px;
  padding: 3px 10px;
  font-size: 13px;
  height: 26px;
}


.features {
  display: flex;
  flex-wrap: wrap;
}

.features span {
  background: #007bff;
  color: white;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 3px;
  padding: 3px 10px;
  font-size: 13px;
  height: 26px;
}

@media (min-width: 500px) {
  .projects-container {
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  }
}
@media (max-width: 500px) {
  .projects-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
@media (max-width: 768px) {
  .project-subtitle {
    font-size: 16px;
    text-align: center;
  }
}
